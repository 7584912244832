import request from './axios';
import http from './http';
//上传图片
export const oupdet = (data) => {
    return http.uploadURL
}

//获取FAQ列表
export const getFAQlist = (data) => {
    return request({
			url: '/problem',
			method: 'get',
			params:data
    })
}
//获取牙医列表
export const getdenlist = (data) => {
    return request({
			url: '/dentist/list',
			method: 'get',
			params:data
    })
}
//获取牙医详情
export const getdendet = (id) => {
    return request({
			url: `/dentist/${id}`,
			method: 'get'
    })
}
//添加论坛
export const addforum = (data) => {
    return request({
			url: `/forum`,
			method: 'post',
			params:data
    })
}
//举报论坛
export const reportforum = (data) => {
    return request({
			url: `/report`,
			method: 'post',
			params:data
    })
}
//修改昵称头像
export const putNickname = (data) => {
    return request({
			url: `/info/editHeadImgOrNickName`,
			method: 'post',
			params:data
    })
}
//修改手机号
export const putphone = (data) => {
    return request({
			url: `/info/editPhone`,
			method: 'post',
			params:data,
			headers: {
				"uuid": "assd"
			},
    })
}
//修改密码
export const putpassword = (data) => {
    return request({
			url: `/members/modifyPass`,
			method: 'put',
			params:data,
    })
}
//获取我的帖子
export const getfourm = (data) => {
    return request({
			url: '/forum/mineList',
			method: 'get',
			params:data
    })
}
//删除我的帖子
export const detfourm = (ids) => {
    return request({
			url: `/forum/${ids}`,
			method: 'DELETE'
    })
}
//取消帖子审核
export const putsfourm = (data) => {
    return request({
			url: `/forum/editForum/${data.id}`,
			method: 'post',
			data
    })
}
//获取我的消息
export const getnews = (data) => {
	return request({
		url: `/memberMsg`,
		method: 'get',
		params:data
	})
}
//获取我的评价
export const getcommt = (data) => {
	return request({
		url: `/memberEvaluation`,
		method: 'get',
		params:data
	})
}
//获取我的优惠券列表
export const getcoupon = (data) => {
	return request({
		url: `/promotion/coupon/getCoupons`,
		method: 'get',
		params:data
	})
}
//获取领券中心列表
export const getcoupons = (data) => {
	return request({
		url: `/promotion/coupon`,
		method: 'get',
		params:data
	})
}
//领取优惠券
export const receive = (couponId) => {
	return request({
		url: `/promotion/coupon/receive/${couponId}`,
		method: 'get'
	})
}
//获取商品详情
export const shopxx = (goodsId) => {
	return request({
		url: `/goods/get/${goodsId}`,
		method: 'get'
	})
}
//获取商品详情
export const integshopxx = (id) => {
	return request({
		url: `/promotion/pointsGoods/${id}`,
		method: 'get'
	})
}
//获取商品规格
export const shopsku = (goodsId,skuId) => {
	return request({
		url: `/goods/sku/${goodsId}/${skuId}`,
		method: 'get'
	})
}
//获取商品评价
export const shoppinj = (goodsId,data) => {
	return request({
		url: `/memberEvaluation/${goodsId}/goodsEvaluation`,
		method: 'get',
		params:data
	})
}
//立即购买、加入购物车
export const shopcart = (data) => {
	return request({
		url: `/trade/carts`,
		method: 'post',
		params:data
	})
}
//获取隐私协议
export const getprivacy = (data) => {
	return request({
		url: `dentist/privatepolicy/${data}`,
		method: 'get'
	})
}